/*
 * @Descripttion:
 * @version:
 * @Author: 小白
 * @Date: 2020-10-05 22:06:34
 * @LastEditors: swcbo
 * @LastEditTime: 2022-05-28 20:54:23
 */
import { White } from '@/typings';
import { lazy } from 'react';
const Splash = lazy(() => import('@/pages/splash'));
const Desc = lazy(() => import('@/pages/desc'));
const Home = lazy(() => import('@/pages/home'));
const Login = lazy(() => import('@/pages/login'));
const Question = lazy(() => import('@/pages/question'));
const Rank = lazy(() => import('@/pages/ranking'));
const NoFound = lazy(() => import('../components/NoFound'));
export const TabBarList: White.RouteTabBar[] = [];

const routes: White.RouteConfig[] = [
  {
    path: '/',
    component: Splash,
    title: '迎端午 赏诗词 赢话费',
  },
  {
    path: '/home',
    component: Home,
    title: '迎端午 赏诗词 赢话费',
  },
  {
    path: '/desc',
    component: Desc,
    title: '端午知识阅读',
  },
  {
    path: '/login',
    component: Login,
    title: '信息确认',
  },
  {
    path: '/rank',
    component: Rank,
    title: '排行榜',
  },
  {
    path: '/question',
    component: Question,
    title: '答题',
  },
  {
    path: '*',
    component: NoFound,
  },
];

export default [...routes];
