/*
 * @Author: swcbo
 * @Date: 2021-09-16 00:12:05
 * @LastEditors: swcbo
 * @LastEditTime: 2022-05-29 10:08:26
 * @FilePath: /autumn/src/App.tsx
 * @Description:
 */
import RouteRender from '@/routers/RouteRender';
import history from '@/utils/history';
import { useLayoutEffect } from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';

const App = () => {
  useLayoutEffect(() => {}, []);
  return (
    <>
      <Router history={history}>
        <RouteRender />
      </Router>
    </>
  );
};
export default App;
